<template>
  <section class="invoice-add-wrapper">


    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Nouveau Type de Ressource"
      ok-title="Enregistrer"
      reset-title="Annuler"
      cancel-title="Annuler"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleOk">
        <b-form-group
          :state="nameState"
          label="Designation du type"
          label-for="name-input"
          invalid-feedback="Designation requise"
        >
          <b-form-input
            id="name-input"
            v-model="newType"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="ressourceData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="6">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Ressource
            </h6>
          </b-col>

          <b-col cols="6">
            <b-button
              v-if="$router.name == 'ressource-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'ressource-view',
                  params: { id: ressourceData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'ressource-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'ressource-view',
                  params: { id: ressourceData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'ressource-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Ressources</span>
            </b-button>

            

            <b-button
                        variant="warning"
                        size="sm"
                        class="mb-75 mr-75 btn-sm float-right"
                        @click="typeAddNew"
                      >
                        Ajouter Type de Ressource
            </b-button>

          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="9">
            <b-row>

              <b-col cols="12" md="3">
                <validation-provider #default="validationContext" name="sku_ressource">
                  <b-form-group
                    label="Code/Ref Unique"
                    label-for="sku_ressource"
                  >
                    <b-form-input id="sku_ressource" v-model="ressourceData.sku_ressource" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Définissez Code Unique svp
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Name -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="name"
                  rules="required"
                >
                  <b-form-group label="Nom de la ressource" label-for="name">
                    <b-form-input
                      id="name"
                      v-model="ressourceData.name"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      Completer le nom s'il vous plait
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- type -->
              <b-col cols="12" md="3">
                <validation-provider
                  #default="validationContext"
                  name="type"
                  rules="required"
                >
                
                  <b-form-group>

                    <label for="type">
                      Type                      
                    </label>

                    <v-select
                      v-model="ressourceData.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                       Completer Type de ressource svp.
                    </b-form-invalid-feedback> 
                  </b-form-group>
                </validation-provider>
              </b-col> 
              
            </b-row>

            <b-row>
              <b-col cols="12" md="3">
                <validation-provider #default="validationContext" name="cost">
                  <b-form-group
                    label="Coût /Prix (d'achat Unitaire)"
                    label-for="cost"
                  >
                    <b-form-input id="cost" v-model="ressourceData.cost" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Définissez le prix d'achat
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3">
                <validation-provider
                  #default="validationContext"
                  name="cost_currency"
                >
                  <b-form-group label="Devise" label-for="cost_currency">
                    <v-select
                      v-model="ressourceData.cost_currency"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="currencyOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="cost_currency"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Définissez l'unité à l'achat
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="3">
                <validation-provider #default="validationContext" name="qty">
                  <b-form-group label="Quantité" label-for="qty">
                    <b-form-input id="qty" v-model="ressourceData.qty" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Completer la Quantité
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3">
                <validation-provider
                  #default="validationContext"
                  name="qty_unit"
                >
                  <b-form-group label="Unité de mesure" label-for="qty_unit">
                    <v-select
                      v-model="ressourceData.qty_unit"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="unitsOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="qty_unit"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Completer l'unité de la Quantité'
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>

              <b-col cols="12" md="8">
                <!-- physical_state -->
                <validation-provider
                  #default="validationContext"
                  name="physical_state"
                  :rules="{ required: true }"
                >
                  <b-form-group
                    label="Etat Actuel"
                    label-for="physical_state"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="ressourceData.physical_state"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="curentStateRessourceOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="physical_state"
                      multiple
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Définissez l'etat actuel de l'élément
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> 
            </b-row>

            <b-row> 
              <b-col cols="12" md="3">
                <b-form-group>
                  <b-form-checkbox
                    id="destroyable"
                    :checked="convertToBool(ressourceData.destroyable)"
                    name="destroyable"
                    v-model="ressourceData.destroyable"
                    switch
                    inline
                  >
                    <span>Peut être détruit </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group>
                  <b-form-checkbox
                    id="expirable"
                    :checked="convertToBool(ressourceData.expirable)"
                    name="expirable"
                    v-model="ressourceData.expirable"
                    switch
                    inline
                  >
                    <span>A une echéance </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="convertToBool(ressourceData.expirable)">
              <b-col cols="6" md="3">
                <!-- date_start -->
                <validation-provider
                  #default="validationContext"
                  name="date_start"
                  rules="required"
                >
                  <b-form-group
                    label="Date debut service"
                    label-for="date_start"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-datepicker
                      id="date_start"
                      v-model="ressourceData.date_start"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: 17 Nov. 2018"
                      class="mb-1"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Sélectionner date début service svp.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col> 

              <b-col cols="6" md="3">
                <!-- date_end -->
                <validation-provider
                  #default="validationContext"
                  name="date_end"
                  rules="required"
                >
                  <b-form-group
                    label="Date Expiration"
                    label-for="date_end"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-datepicker
                      id="date_end"
                      v-model="ressourceData.date_end"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: 17 Nov. 2028"
                      class="mb-1"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Sélectionner date expiration svp.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="6" md="3">
                <!-- waranty -->
                <validation-provider
                  #default="validationContext"
                  name="waranty"
                  rules="required"
                >
                  <b-form-group
                    label="Garantie"
                    label-for="waranty"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="waranty"
                      v-model="ressourceData.waranty"
                      :state="getValidationState(validationContext)"
                      trim
                      type="number"
                      placeholder="Ex: 5"
                      class="mb-1"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Sélectionner garantie svp.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="3">
                <validation-provider
                  #default="validationContext"
                  name="waranty_unit"
                >
                  <b-form-group
                    label="Unité Temps Garantie"
                    label-for="waranty_unit"
                  >
                    <v-select
                      v-model="ressourceData.waranty_unit"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="timeUnitsOptions"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="waranty_unit"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      Définissez l'unité de temps de garantie
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12">
                <b-form-group
                  label="Autre(s) Description(s)"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="ressourceData.description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="3" class="mb-12" style="margin-bottom: 25px"> 
            <b-media no-body>
              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->

                <b-form-file
                  ref="refInputEl"
                  v-model="imageFile"
                  accept=".jpg, .png, .gif"
                  plain
                  hidden
                  @input="onFileChange"
                />
              </b-media-body>
            </b-media>
            <!--/ media -->

            <!-- Media -->
            <b-media class="mb-12">
              <template #aside>
                <div>
                  <b-avatar
                    ref="previewEl"
                    :src="ressourceData.image_url"
                    :text="avatarText(ressourceData.name)"
                    :variant="`light-${resolveDataTypeVariant(
                      ressourceData.name
                    )}`"
                    @input="inputImageRenderer"
                    style="width: 100%; height: 100%"
                    rounded
                  />
                  <br />
                  <b-button
                    v-if="$router.currentRoute.name != 'ressource-view'"
                    variant="primary"
                    size="sm"
                    class="mb-75 mr-75"
                    style="width: 100%"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    {{ imageActionText }}
                  </b-button>
                </div>
              </template>
            </b-media>
          </b-col>
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="ressourceData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import formValidation from "@core/comp-functions/forms/form-validation";
// ressources
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BImg,
  BMediaBody,
  BMedia,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";

// alerts

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import storeModule from "./storeModule";
import useModuleList from "./useList";
import router from "@/router";
import { avatarText } from "@core/utils/filter";
const moduleName = "ressource";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      bankOptions: [],

      nameState: null,
      newType: "",
      imageFile: null,
      imageActionText: "",
      image_changed: false,
      image_url: "",
    };
  },
  props: {
    ressourceData: {
      type: Object,
    },
    defaultressourceData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    if (router.currentRoute.name == moduleName + "-add-new") {
      var now = new Date();
      this.ressourceData.sku_ressource = "SKU-" + now.getTime();

      this.operationTitle = "Nouvelle ";
      this.imageActionText = "Ajouter Photo ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.ressourceData.sku_ressource = router.currentRoute.params.id;
      this.operationTitle = "Détails de la ";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }

    if (router.currentRoute.name == moduleName + "-edit") {
      this.ressourceData.sku_ressource = router.currentRoute.params.id;
      this.operationTitle = "Modification de la ";
      this.operationIcon = "EditIcon";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Photo";
      this.actionIndex = 3;
      this.showed = true;
    }

    // get units
    await myApi
      .get(`unit`)
      .then((response) => {
        // default action
        var data = response.data.data;
        console.log(data);

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.unitsOptions.push({
            label:
              data[i].name ,
            value: data[i].name,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
      
      // get currencyOptions
    await myApi
      .get(`currency`)
      .then((response) => {
        // default action
        var data = response.data.data;
        console.log(data);

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.currencyOptions.push({
            label:
              data[i].name ,
            value: data[i].name,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    typeAddNew(value) {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    handleOk() {

      // Push the new name to types
      console.log("submit new type...")

      this.typeOptions.push({
            label:
            this.newType,
            value: this.newType,
          });
 
      this.ressourceData.type = this.newType;

      // Push the new name to types.0
      console.log(this.typeOptions)
    },
    resetModal() {
      this.newType = ""
      this.nameState = null;
    },
    convertToBool(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
    convertBoolToInt(value) {
      if (value == true) {
        return 1;
      } else {
        return 0;
      }
    },
    async onFileChange(e) {
      const file = this.imageFile;

      let formData = new FormData();
      formData.append("picture", file);
      formData.append("sku_ressource", this.sku_ressource);

      var defaultActionText = this.imageActionText;
      this.imageActionText = "Envoie Photo...";

      await axios
        .post("upload_ressource_image", formData, {
          header: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.status == 200) {
            // preview image (refresh)
            this.image_url = response.data.full_url;

            this.image_changed = true;

            this.imageActionText = defaultActionText;

            // preview
            this.ressourceData.image_url = this.image_url;

            // console.log(this.ressourceData.image_url);
            // console.log(this.$props.ressourceData.image_url);

            this.showToast(
              "Envoie Photo",
              response.data.message,
              "success",
              "CheckIcon",
              "top-center",
              2000
            );

            // local session update
            // optionsLocal.image_url = this.image_url;
          } else {
            this.imageActionText = defaultActionText;
            this.errors.push(response.message);
            this.showToast(
              "Erreur Survenu",
              response.message,
              "success",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          this.imageActionText = defaultActionText;

          this.alertAutoClose(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            5000,
            "btn btn-warning"
          );
        });
    },
    reinitForm() {
      this.$props.ressourceData = this.defaultressourceData;

      if (router.currentRoute.name == "ressource-add-new") {
        // init empty ressource data
        // ressourceData = this.defaultressourceData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     ressourceData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       ressourceData.value = undefined;
        //     }
        //   });
      }
      // remove all occurences
      // initialise 1 element
      // this.$props.ressourceData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "ressource-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "ressource-view") {
        method = "get";
        params = "/" + this.ressourceData.id;
      }
      if (router.currentRoute.name == "ressource-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.ressourceData.id;
      }

      // finalise form data
      formData = this.$props.ressourceData;

      await myApi({
        url: "ressource" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "ressource-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.errors,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "ressource-view" });
    },
    goToNextItem() {
      router.push({ name: "ressource-list" });
    },
    goToList() {
      router.push({ name: "ressource-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // ressource
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup(props) {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-ressource";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const { resolveDataTypeVariant } = useModuleList();

    let resetressourceData = () => {
      // this.ressourceData = this.$props.ressourceData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetressourceData);

    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.ressourceData.avatar = base64;
      }
    );

    const unitsOptions = [];
    unitsOptions.push({ label: "Unité(s)", value: "Unité(s)" });
    unitsOptions.push({ label: "Element(s)", value: "Element(s)" });
    unitsOptions.push({ label: "Piece(s)", value: "Piece(s)" });
    unitsOptions.push({ label: "Packet(s)", value: "Packet(s)" });
    unitsOptions.push({ label: "Carton(s)", value: "Carton(s)" });
    unitsOptions.push({ label: "Mesure(s)", value: "Mesure(s)" });
    unitsOptions.push({ label: "Gramme(s)", value: "Gr" });
    unitsOptions.push({ label: "Kilo-gramme(s)", value: "Kg" });
    unitsOptions.push({ label: "Tonne(s)", value: "Tn" });
    unitsOptions.push({ label: "Mili-litre(s)", value: "Ml" });
    unitsOptions.push({ label: "Litre(s)", value: "Lt" });
    unitsOptions.push({ label: "Metre-Cube(s)", value: "M3" });
    unitsOptions.push({ label: "Mili-metre(s)", value: "Mm" });
    unitsOptions.push({ label: "Metre(s)", value: "Mt" });
    unitsOptions.push({ label: "Km(s)", value: "Km" });
    unitsOptions.push({ label: "Metre-Carré(s)", value: "M2" });
    unitsOptions.push({ label: "Are(s)", value: "Ar" });
    unitsOptions.push({ label: "Hectare(s)", value: "Ha" });

    const currencyOptions = [];
    currencyOptions.push({ label: "Dollars Americain", value: "USD" });
    currencyOptions.push({ label: "Francs Congolais", value: "CDF" });
    currencyOptions.push({ label: "Dirhams Emiratis", value: "AED" });
    currencyOptions.push({ label: "Yuans Chinois", value: "CYE" });
    currencyOptions.push({ label: "Francs CFA", value: "FCFA" });
    currencyOptions.push({ label: "Shillings Kenyane", value: "KSH" });
    currencyOptions.push({ label: "Shillings Ugandais", value: "USH" });
    currencyOptions.push({ label: "Shillings Tanzanien", value: "TSH" });
    currencyOptions.push({ label: "Francs Burundais", value: "BFW" });
    currencyOptions.push({ label: "Francs Rwandais", value: "RFW" });

    if (props.requirementData != null && props.requirementData != undefined) {
      for (const [key, value] of Object.entries(props.requirementData.units)) {
        unitsOptions.push({ label: value.name, value: value.symbol });
      }
    }

    const curentStateRessourceOptions = [
      { label: "Neuf", value: "Neuf" },
      { label: "Fonctionnel", value: "Fonctionnel" },
      { label: "Déjà Utilisé", value: "Déjà Utilisé" },
      { label: "Seconde Main", value: "Seconde Main" },
      { label: "En Panne", value: "En Panne" },
      { label: "A déclasser", value: "A déclasser" },
      { label: "A géter", value: "A géter" },
      { label: "Déclassé", value: "Déclassé" },
      { label: "Usagé", value: "Usagé" },
      { label: "Non-Specifié", value: "Non-Specifié" },
    ];

    const typeOptions = [
      { label: "Imobilisation", value: "imobilisation" },
      { label: "Mobilisation", value: "mobilisation" },
      { label: "Valeur Circulante", value: "value" },
    ];

    const timeUnitsOptions = [
      { label: "Seconde", value: "sec" },
      { label: "Minute", value: "min" },
      { label: "Heure", value: "hour" },
      { label: "Jour", value: "day" },
      { label: "Mois", value: "month" },
      { label: "Année", value: "year" },
    ];

    return {
      refFormObserver,
      getValidationState,
      currencyOptions,
      resetForm,

      unitsOptions,
      currencyOptions,
      curentStateRessourceOptions,
      typeOptions,
      timeUnitsOptions,

      avatarText,
      resolveDataTypeVariant,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>